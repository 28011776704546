<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogTempPassword"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title>
          <span
            id="temp_title"
            v-translate='{ caller: callerName }'
          >Temporary Password Reset Successful for: "%{ caller }"</span>
        </v-card-title>
        <v-card-text>
          <span v-if="featureFlags.passwordResetType !== 'PASSPHRASE'">
            <translate>The callers account was updated with following temporary password.</translate>
          </span>
          <span v-else-if="featureFlags.passwordResetType === 'PASSPHRASE'">
            <translate>The callers account was updated with following temporary passphrase.</translate>
          </span>
        </v-card-text>

        <v-card-text>
          <span v-if="featureFlags.passwordResetType !== 'PASSPHRASE'">
            <translate>Temporary password:</translate>
          </span>
          <span v-else-if="featureFlags.passwordResetType === 'PASSPHRASE'">
            <translate>Temporary passphrase:</translate>
          </span>
          <v-chip
            id="cv_temp_pass"
            class="ma-2"
            color="success"
            label
            outlined
          >
            {{ tempPassword }}
          </v-chip>

          <v-btn
            icon
            color="success"
            @click="copyToClipBoard"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            id="temp_close"
            @click="dialogTempPassword = false;"
          >
            <translate>Close</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import {translate} from 'vue-gettext';
// const {gettext: $gettext} = translate;

export default {
  name: 'TempPassword',


  props: {
    profile: Object,
    value: Boolean,
    tempPassword: String,
    featureFlags: Object
  },
  data: () => ({}),
  computed: {
    // expose a computed parameter that returns the :value property of this
    // component on retrieval and emits an close event to the parent to close
    // the dialog when set to 'false'.
    dialogTempPassword: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    },
    callerName() {
      if (this.profile && this.profile.profile) {
        return this.profile.profile.firstName + ' ' + this.profile.profile.lastName;
      }
      else {
        return "";
      }
    }
  },
  methods: {
    copyToClipBoard() {
      navigator.clipboard.writeText(this.tempPassword);
    }
  }
}
</script>
