<template>
  <v-row justify="center">
    <v-dialog v-model="dialogLogDetails" persistent max-width="825">
      <v-card>
        <v-card-title>
          <span id="logs_details_title" v-translate='{caller: userLogin}'>System logs for: "%{ caller }"</span>
          <v-spacer></v-spacer>
          <v-text-field
            id="log_details_search"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          id="cv_log_details"
          dense
          :headers="headers"
          :items="eventlog"
          :search="search"
          :loading="eventsLoading"
        >
          <template v-slot:item.outcome.result="{ item, index }">
            <div v-if="item.outcome.result === 'FAILURE'" :id="'cv_logid_'+index">
              <v-chip color="error">{{ item.outcome.result }}</v-chip>
            </div>
            <div v-else :id="'cv_logid_'+index">
              {{ item.outcome.result }}
            </div>
          </template>
          <template v-slot:loading>
            <div id="cv_logs_loading"><translate>Loading system event logs...Please wait</translate></div>
          </template>
          <template v-slot:no-data>
            <div><div id="cv_logs_no_data"><translate>No data available</translate></div></div>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              color="primary"
              id="cv_log_details_close"
              @click="dialogLogDetails = false"
            >
              <translate>Close</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {translate} from 'vue-gettext';

const {gettext: $gettext} = translate;

export default {
  name: 'CallerLogDetails',

  // Component properties
  //
  // profile - project object for the selected user.
  // value   - controls if the dialog is visible or hidden.
  props: {
    profile: Object,
    value: Boolean
  },
  computed: {
    // expose a computed parameter that returns the :value property of this
    // component on retrieval and emits an close event to the parent to close
    // the dialog when set to 'false'.
    dialogLogDetails: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.eventlog = [];
          this.$emit('close', 'log-details');
        }
      }
    },
    userLogin() {
      if(this.profile && this.profile.profile) {
        return this.profile.profile.login;
      }
      else {
        return "";
      }
    },
    headers() {
      return [
        { text: this.t('Time'), value: 'published' },
        { text: this.t('Event'), value: 'displayMessage' },
        { text: this.t('Reason'), value: 'outcome.reason' },
        { text: this.t('Result'), value: 'outcome.result' }
      ];
    }
  },
  watch: {
    dialogLogDetails(newValue) {
        if(newValue) {
            this.loadDetailedLogs(this.profile.id)
        }
    }
  },
  data () {
      return {
        eventsLoading: false,
        search: '',
        eventlog: [],
      }
    },
  methods: {
    t: function (transval) {
      return $gettext(transval);
    },
    loadDetailedLogs: function(userid) {

      // Start the loading indicator
      this.eventsLoading = true;
      this.search = ''
    

      this.$ajax.get(`/api/v1/logs/${userid}/details`).then(response => {
        if (response.status == 200 && response.data) {
          const data = response.data;
          if (data.status) {
            this.eventlog = data.events
          } else {
            // Emit a notification with the error message
            this.$emit('notify', {
              title: $gettext('Error: Loading System Logs'),
              body: $gettext('An error occurred while loading system logs.'),
              type: 'error',
              timeout: -1
            });
          }
        }
      }).catch(err => {
        console.error(err);

        // Emit a notification with the error message
        this.$emit('notify', {
          title: $gettext('Error: Loading System Logs'),
          body: $gettext('An error occurred while loading system logs.'),
          type: 'error',
          timeout: -1
        });
      }).finally(() => {
        // Stop the loading indicator
        this.eventsLoading = false;
      });
    }
  }
};

</script>

<style scoped>

</style>>
