<template>
  <v-footer app height="auto" color="secondary">
    <v-row class="my-0"
      align="center" align-content="center" justify="center"
    >
      <v-col class="white--text text-center text-sm-left mx-sm-0 mt-sm-0 pr-sm-0 flex-grow-0 text-no-wrap">
        Caller Verify v{{ vue_version }} &copy; 2020-2023<span class="white-space hidden-xs-only"> - </span>
      </v-col>

      <v-col class="white--text text-center text-sm-left mx-sm-0 mt-sm-0 pl-sm-0 text-no-wrap">
        TechJutsu Inc. - <translate>Patent Pending</translate>
      </v-col>

      <!-- TODO: Only show when the user is logged in and in an admin role -->
      <v-col v-if="profile && profile.name && settings.permission == 'read-write'"
        class="text-center mx-sm-0 mt-sm-0 text-no-wrap ml-auto flex-grow-0"
      >
        <a
          id="cv_okta_admin_link"
          target="_blank"
          :href="settings.oktaOrgUri + '/home/admin-entry?fromURI=/admin/users'"
          class="white--text text-decoration-none"
        >
          <v-icon color="white">mdi-launch</v-icon>
          <translate>Okta Admin Portal</translate>
        </a>
      </v-col>        
    </v-row>
    <LanguageSelector/>
  </v-footer>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector';

export default {
  name: 'CallerVerifyFooter',
  
  components: {
    LanguageSelector,
  },

props: [
    'settings',
    'profile'
  ],

  data: () => ({
    vue_version: process.env.VUE_APP_VERSION
  }),
};
</script>

<style scoped>
.white-space {
  white-space: pre;
}

@media (max-width: 600px) {
  .col {
    margin: 0 auto;
  }
}
</style>
