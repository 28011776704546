<template>
  <div class="text-center">
    <v-menu
      open-on-hover
      top
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="cv_language"
          color="secondary"
          dark
          depressed
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-earth</v-icon>
        <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </template>
      <!-- list to hold the available languages --> 
      <v-list>
        <v-list-item-group mandatory v-model="selectedItem" color="primary">
          <v-list-item v-for="(language, key) in $language.available" :key="key" :value="key" @click="changeLanguage(key)">
            <v-list-item-title>{{ language }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  data: () => ({
    selectedItem: 'en',
  }),
  methods: {
    changeLanguage: function(language) {
      // change both the vuetify language as well as the vue-gettext language.
      this.$vuetify.lang.current = language
      this.$root.$language.current = language

      // language overridden, set a cookie to keep track fo this. 
      this.$cookies.set('preferredLang', language);
    }
  },
  mounted() {
    // Load the preferred language from cookie (if available)
    if (this.$cookies.get('preferredLang')) {
      const preferredLang = this.$cookies.get('preferredLang');
      this.selectedItem = preferredLang
      this.changeLanguage(preferredLang)
    }
    else {
      // no cookie use whatever the browser language for the language selector
      this.selectedItem = this.$root.$language.current
    }
  }
};
</script>

<style scoped>
</style>