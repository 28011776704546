<template>
  <v-row justify="center">

    <!-- Reset All Factors Confirmation Dialog -->
    <v-dialog
      v-model="dialogMFAResetAll"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title>
          <span v-translate='{caller: callerName}'>Reset all factors for: "%{ caller }"</span>
        </v-card-title>
        <v-card-text>
          <translate>Are you sure you want to reset all factors for the selected profile?</translate>
          <v-list dense v-if="profile.mfa">
            <v-list-item v-for="(factor, factorId) in factorList" :key="factorId">
              <v-list-item-icon>
                <v-icon>mdi-circle-medium</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :id="'cv_factor_reset_'+factor.type" v-translate='{factorDescription: $cvutils.getTranslatedFactorDesc(factor)}'>%{ factorDescription }</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-subtitle>
          <v-alert type="info" dense>
            <strong><translate>Please Note:</translate> </strong>
            <translate>Resetting all factors may also include factors not listed here (such as SMS, Email, Security Question, etc.)</translate>
            <br/>
            <translate>The caller must also remove the reset factor from the registered device(s).</translate>
          </v-alert>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!-- confirmation button -->
          <v-btn
            id="resetall_confirm"
            color="success"
            @click="resetAllFactors()"
            :loading="mfaResetInProgress"
          >
            <translate>Confirm</translate>
          </v-btn>

          <!-- cancel button -->
          <v-btn
            id="resetall_cancel"
            color="primary"
            @click="dialogMFAResetAll = false"
            :disabled="mfaResetInProgress"
          >
            <translate>Cancel</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {translate} from 'vue-gettext';
const {gettext: $gettext} = translate;

export default {
  name: 'ResetMFAAll',

  // Component properties
  //
  // profile     - project object for the selected user.
  // factorsList - list of factors that will be reset.
  // value       - controls if the dialog is visible or hidden.
  //
  props: {
    profile: Object,
    factorList: Object, 
    value: Boolean
  },

  computed: {
    // expose a computed dialog parameter that returns the :value property
    // of this component on retrieval and emits an close event to the parent
    // to close the dialog when set to 'false'.
    dialogMFAResetAll: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.$emit('close', 'mfa-reset-all');
        }
      }
    },
    // FIXME: we should make this a return property from our API call so we don't have to do this.
    callerName() {
      if(this.profile && this.profile.profile) {
        return this.profile.profile.firstName + ' ' + this.profile.profile.lastName;
      }
      else {
        return "";
      }
    }
  },

  data: () => ({
    // Control for reset in progress spinner.
    mfaResetInProgress: false,
  }),
  methods:  {
    resetAllFactors: function() {
      console.log(`Reset all factors for user: ${this.profile.id}`);

      // Enable the progress indicator
      this.mfaResetInProgress = true;

      this.$ajax.post(`/api/v1/users/${this.profile.id}/factors/reset`).then(response => {
        // handle the case when we don't get a valid response back, but we got success HTTP code,
        // but an unexpected response body, present a generic Failed to send OTP message.
        const message = (response.data && response.data.message) ? response.data.message : 'Unknown reason.';
        if (!response.data || !response.data.status) {
          this.$emit('notify', {
            title: $gettext('Factor Reset Failed'),
            body: $gettext(message),
            type: 'error',
            timeout: -1
          });
          return;
        }

        // CLAIM: Reset of all factors was successful - refresh them from the backend.
        this.$emit('reloadFactors',  {profile: this.profile});

        this.$emit('notify', {
          title: $gettext('Factor Reset Success'),
          body: $gettext(message),
          type: 'success'
        });

      }).catch(
        // Handle when we get back some sort of HTTP error response.
        error => {
          // prepare an error notification
          let notification = {
            title: $gettext('Factor Reset Failed'),
            type: 'error',
            body: '',
            timeout: null,
            showAuthBtn: false
          }

          // get more detailed error information (if we have it)
          if(error.response) {
            const response_code = error.response.status;
            const response_data = error.response.data;

            // 401 unauthorized typically does not contain a message so include a default.
            if(response_code === 401) {
              notification.body = $gettext(response_data.message || 'Invalid request token, please re-login.');

              // provide a login button directly in the dialog as well.
              notification.showAuthBtn = true;
              notification.timeout = -1;
            }
            else {
              notification.body = $gettext(response_data.message || 'Unknown error.');
            }
          }
          else {
            // error, but no details, provide what we can.
            notification.body = $gettext('An error occurred while attempting to reset the factor.');
            console.error(`There was an unknown error: ${error}`);
          }

          // display error notification to the user
          this.$emit('notify', notification);
      }
      ).finally(() => {
        // Stop the in progress indicator and close the confirm dialog
        this.mfaResetInProgress = false;
        this.dialogMFAResetAll = false;
      });
    }
  }
}
</script>
