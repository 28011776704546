<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title>
          <span
            id="resetpwd_title"
            v-translate='{ caller: callerName }'
          >Reset password for: "%{ caller }"</span>
        </v-card-title>

        <v-card-text>
          <v-radio-group v-model="resetMethod">
            <v-radio
              selected
              key="resetLink"
              id="resetLink"
              name="resetLink"
              :label="$gettext('Send the caller a self-service reset email')"
              value="link"
            ></v-radio>
            <v-radio
              key="resetTemp"
              id="resetTemp"
              name="resetTemp"
              :label="featureFlags.passwordResetType !== 'PASSPHRASE' ? $gettext('Use a randomly generated temporary password') : $gettext('Use a randomly generated temporary passphrase')"
              value="temp"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Confirmation button for the reset dialog -->
          <v-btn
            id="reset_confirm"
            color="success"
            @click="resetPassword()"
            :loading="resetInProgress"
          >
            <translate>Confirm</translate>
          </v-btn>

          <!-- Cancel button for the reset dialog -->
          <v-btn
            id="reset_cancel"
            color="primary"
            @click="resetMethod = 'link'; dialog = false;"
            :disabled="resetInProgress"
          >
            <translate>Cancel</translate>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <TempPassword
      v-model=" dialogTempPassword "
      :profile=" profile "
      :tempPassword=" tempPassword "
      :featureFlags=" featureFlags "
      @close=" closeAndClearTempPassword "
    />
  </v-row>
</template>

<script>
import { translate } from 'vue-gettext';
const { gettext: $gettext } = translate;

import TempPassword from '@/components/ActionDialogs/TempPassword';

export default {
  name: 'ResetPassword',

  components: {
    TempPassword
  },

  // Component properties
  //
  // profile - project object for the selected user.
  // value   - controls if the dialog is visible or hidden.
  //
  props: {
    profile: Object,
    value: Boolean,
    featureFlags: Object
  },
  computed: {
    // expose a computed dialog parameter that returns the :value property
    // of this component on retrieval and emits an close event to the parent
    // to close the dialog when set to 'false'.
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('close', 'reset-password');
        }
      }
    },
    // FIXME: we should make this a return property from our API call so we don't have to do this.
    callerName() {
      if (this.profile && this.profile.profile) {
        return this.profile.profile.firstName + ' ' + this.profile.profile.lastName;
      }
      else {
        return "";
      }
    }
  },
  data: () => ({
    // control spinner and availability to buttons when reset request is in progress.
    resetInProgress: false,

    dialogTempPassword: false,

    // attributes for the password reset form
    resetMethod: 'link',

    tempPassword: ''
  }),

  methods: {
    resetPassword() {
      // bail out of the component wasn't initialized properly
      if (this.profile === null || this.profile.id === null) {
        return;
      }

      // startup a spinner for the unlock action.
      this.resetInProgress = true;

      // issue an REST API call to reset the user password
      this.$ajax.post(`/api/v1/users/${this.profile.id}/password/reset`, {
        'method': this.resetMethod
      }).then(response => {

        let messageBody = response.data ? response.data.message : null;

        if (response.data && response.data.status) {
          if (this.resetMethod === 'link') {
            this.$emit('notify', {
              title: $gettext('Reset Successful'),
              body: $gettext(messageBody || 'Password reset succeeded'),
              type: 'success'
            });

            // update the callers status in parent component
            this.$emit('updateStatus', { profile: this.profile, status: 'RECOVERY'});
          }
          else if (this.resetMethod === 'temp') {
            // update the callers status in parent component
            this.$emit('updateStatus', { profile: this.profile, status: 'PASSWORD_EXPIRED'});

            // Open the temporary password dialog
            if (response.data.tempPassword) {
              this.tempPassword = response.data.tempPassword;
              this.dialogTempPassword = true;
            }
          }

        }
        // Reset API call failed or didn't return a result -> display an error notification to the user.
        else {
          this.$emit('notify', {
            title: $gettext('Reset Failed'),
            body: $gettext(messageBody || 'Reset failed to process'),
            type: 'error'
          });
        }
      }).catch(error => {
        // prepare an error notification
        let notification = {
          title: $gettext('Reset Failed'),
          type: 'error',
          body: '',
          timeout: null,
          showAuthBtn: false
        }

        // get more detailed error information (if we have it)
        if (error.response) {
          const response_code = error.response.status;
          const response_data = error.response.data;

          // 401 unauthorized typically does not contain a message so include a default.
          if (response_code === 401) {
            notification.body = $gettext(response_data.message || 'Invalid request token, please re-login.');

            // provide a login button directly in the dialog as well.
            notification.showAuthBtn = true;
            notification.timeout = -1;
          }
          else {
            notification.body = $gettext(response_data.message || 'Unknown error.');
          }
        }
        else {
          // error, but no details, provide what we can.
          notification.body = $gettext('An unknown error occurred while processing reset request')
          console.error(`There was an unknown error: ${error}`);
        }

        // display error notification to the user
        this.$emit('notify', notification);
      }).finally(() => {
        // Stop the processing indicator, close the dialog, and reset the method
        this.resetInProgress = false;
        this.resetMethod = 'link';
        this.dialog = false;
      });
    },
    closeAndClearTempPassword() {
      this.tempPassword = '';
      this.dialogTempPassword = false;
    }
  }
}
</script>
