<template>
  <v-container fluid>
    <v-container style="max-width: 600px;">
      <v-row
        :no-gutters="$appConfig.widget"
        :dense="$appConfig.widget"
        align="center"
        justify="center"
        class="mx-0 mx-sm-2"
      >
        <v-col>
          <v-select
            :dense="$appConfig.widget"
            :disabled="$appConfig.disableLookup"
            id="cv_lookup_type"
            :item-text="item => item[$language.current] || $gettext(item.text)"
            :items="settings.attributes"
            v-model="selectedAttr"
            :label="$gettext('Attribute')"
            outlined
            color="#333"
            item-color="secondary"
          ></v-select>
        </v-col>
      </v-row>
      <v-row
        :no-gutters="$appConfig.widget"
        :dense="$appConfig.widget"
        v-if="selectedAttr === 'firstAndLastName'"
        align="center"
        justify="center"
        class="mx-0 mx-sm-2"
      >
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            :dense="$appConfig.widget"
            :disabled="$appConfig.disableLookup"
            v-model="firstName"
            name="firstName"
            :label="$gettext('First Name')"
            id="firstName"
            ref="firstName"
            outlined
            clearable
            color="#333"
            spellcheck="false"
            @keyup.enter="searchUser()"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            :dense="$appConfig.widget"
            :disabled="$appConfig.disableLookup"
            v-model="lastName"
            name="lastName"
            :label="$gettext('Last Name')"
            id="lastName"
            ref="lastName"
            outlined
            clearable
            color="#333"
            spellcheck="false"
            @keyup.enter="searchUser()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row
        :no-gutters="$appConfig.widget"
        :dense="$appConfig.widget"
        v-if="selectedAttr !== 'firstAndLastName'"
        align="center"
        justify="center"
        class="mx-0 mx-sm-2"
      >
        <v-col
          align-self="center"
          class="mx-auto"
        >
          <v-text-field
            :dense="$appConfig.widget"
            :disabled="$appConfig.disableLookup"
            v-model="value"
            name="value"
            :label="$gettext('Value')"
            id="value"
            ref="value"
            :placeholder="$gettext('Enter the caller provided value')"
            clearable
            outlined
            color="#333"
            spellcheck="false"
            @keyup.enter="searchUser()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row
        :no-gutters="$appConfig.widget"
        :dense="$appConfig.widget"
        align="center"
        justify="center"
      >
        <v-col class="text-center text-sm-right mr-0 mr-sm-5">
          <v-btn
            :small="$appConfig.widget"
            id="cv_lookup_button"
            color="success"
            :loading="lookupLoading"
            @click="searchUser()"
          >
            <translate>Lookup Caller</translate>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <CustomersTable
      v-if="customers !== null && customersFound > 0"
      :customers="customers"
      :optionalResultAttrs="optionalResultAttrs"
      :customers-found="customersFound"
      :settings="settings"
      @showNotification="showNotification($event)"
      @updateStatus="updateStatus"
      @reloadFactors="reloadFactors($event)"
    />

    <NetworkStatus />

    <v-snackbar
      app
      v-model="notification.show"
      :timeout="notification.timeout"
      :color="notification.type"
      :width="calculatedWidth"
      vertical
      :centered="$appConfig.widget"
    >
      <div
        id="cv_notif_title"
        class="text-h5 mb-3"
      >{{ notification.title }}</div>
      <div id="cv_notif_body">{{ notification.body }}</div>

      <template v-slot:action>
        <v-btn
          id="cv_notif_btn_login"
          v-if="notification.showAuthBtn"
          text
          @click="$auth.signInWithRedirect({ originalUri: '/verify' })"
        >
          <translate>Login</translate>
        </v-btn>
        <v-btn
          id="cv_notif_btn_close"
          v-if="!notification.showAuthBtn"
          text
          @click.native="notification.show = false"
        >
          <div>
            <translate>Close</translate>
          </div>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { translate } from 'vue-gettext';

import CustomersTable from '@/components/CustomersTable';
import NetworkStatus from '@/components/NetworkStatus';

const { gettext: $gettext } = translate;

export default {
  name: 'CallerVerify',

  components: {
    CustomersTable,
    NetworkStatus
  },

  // Component properties
  //
  // settings - additional configuration settings for CallerVerify including:
  // * attributes to search on.
  // * default attribute to select.
  // * feature flags enabled/disabled.
  //
  props: {
    settings: Object
  },


  data: () => ({
    value: null,
    firstName: null,
    lastName: null,
    selectedAttr: null,
    skipValueClear: false,

    customers: {},
    optionalResultAttrs: [],

    lookupLoading: false,

    errorMessage: '',

    notification: {
      showAuthBtn: false,
      show: false,
      timeout: 5000,
      title: '',
      body: '',
      type: 'info'
    }
  }),

  watch: {
    // when selectedAttr changes, make sure we clear the input fields
    selectedAttr: function (value) {

      // only clear the values if we don't need to skip that.
      if (!this.skipValueClear) {
        this.value = '';
        this.firstName = '';
        this.lastName = '';
      }
      // only skip once, so the next time we clear it correctly.
      else {
        this.skipValueClear = false;
      }

      if (value === 'firstAndLastName') {
        setTimeout(() => {
          this.$refs.firstName.focus();
        }, 200);
      } else {
        setTimeout(() => {
          this.$refs.value.focus();
        }, 200);
      }
    },
    // make sure we initialize selectedAttr to the configured value when its loaded.
    'settings.selectedAttr': function (value) {
      if (!this.selectedAttr) {

        // DOC: There is a bit of a race condtion in widget mode when  setting the value below and
        // updating selectedAttr here.  When selectedAttr gets updated, it triggers the watch above
        // which runs after this.. that in turn clears out the value (this.value) which could either
        // (1) worst case impact and break the search, or (2) still lookup the user, but clear the
        // value so the UI looks broken.   To avoid this we introduce a temporary skip for clearing
        // the value on this.selectedAttr changes.
        if (this.$appConfig.lookupCaller) {
          this.skipValueClear = true;
        }
        this.selectedAttr = value;

        // Focus the "value" input field initially
        setTimeout(() => {
          this.$refs.value.focus();
        }, 200);

        // if a caller was passed in search for that caller too.
        if (this.$appConfig.lookupCaller) {
          this.value = this.$appConfig.lookupCaller;
          this.searchUser();
        }
      }
    }
  },

  computed: {
    calculatedWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '100%'
        default: return '400px'
      }
    },
    customersFound() {
      return this.customers === null ? 0 : Object.keys(this.customers).length;
    }
  },
  methods: {
    buildFactorsTooltip: function(customer, factors) {
      let enrolledFactors = [];
      let pendingFactors = false;

      // process all of the factors returned so we can determine what to display.
      for (const factor of Object.values(factors)) {
        if (factor.status === 'ACTIVE' || factor.status === 'DISABLED') {
          // only add if not already in there to avoid making the tooltip huge
          // when there is multiple OV registrations.
          let translatedFactor = this.$cvutils.getTranslatedFactorDesc(factor);
          if(enrolledFactors.indexOf(translatedFactor) === -1) {
            enrolledFactors.push(translatedFactor);
          }
        }
        else if (factor.status === 'PENDING_ACTIVATION') {
          pendingFactors = true;
        }
      }

      // if we have any active / supported factors then we are sufficiently enrolled.
      if(enrolledFactors.length > 0) {
        // Build the tooltip and update the customer object, note these are
        // pre-translated above as we push each element into the list.
        let tooltip = enrolledFactors.join(', ');
        customer.mfa = {
          'status': 'ENROLLED', // status gets translated in CustomersTable.vue
          'tooltip': tooltip,
          'factors': factors
        }
      }
      // if we have pending factors, make the status pending.
      else if (pendingFactors) {
        customer.mfa = {
          'status': 'PENDING',
          'tooltip': $gettext('Factors appear to be pending activation'),
          'factors': factors
        }
      }
      // else, we either have only enroll-able factors or no factors, either way
      // we are in NOT SUPPORTED state, but if we have enroll-able factors we need
      // to allow them to be enrolled (if enabled).
      else {
        // No enrolled factors found.
        customer.mfa = {
          'status': 'NOT SUPPORTED',
          'tooltip': $gettext('No supported factors enrolled'),
          'factors': factors
        }
      }
    },
    updateStatus(statusUpdate) {
      const customer = this.customers[statusUpdate.profile.id]
      if (customer) {
        // if the current status is locked out and we unlocked them OR we reset their password.
        // then we need to force a factor reload to ensure their set of available factors is properly
        // reflected.
        let reloadFactors = false;
        if( (customer.status == 'LOCKED_OUT' && statusUpdate.status == 'ACTIVE') ||
            (statusUpdate.status == 'RECOVERY') || (statusUpdate.status == 'PASSWORD_EXPIRED') ){
          reloadFactors = true;
        }
        customer.status = statusUpdate.status;
        if (statusUpdate.mfa && statusUpdate.mfa.status) {
          customer.mfa.status = statusUpdate.mfa.status;
        }
        // now its safe to reload the factors.
        if(reloadFactors) {
          this.loadCallerFactors(customer.id, customer);
        }
      }
    },
    // wrapper for loadCallerFactors called from a child component.
    reloadFactors(event) {
      if (event && event.profile) {
        this.loadCallerFactors(event.profile.id, event.profile);
      }
    },
    loadCallerFactors(userid, customer) {
      // allow verify on these statuses.
      let valid_statuses = ['LOCKED_OUT', 'ACTIVE', 'PASSWORD_EXPIRED', 'RECOVERY']

      // don't load factor for callers in an invalid status.
      if (!valid_statuses.includes(customer.status)) {
        customer.mfa = {
          'status': 'INVALID STATUS',
          'tooltip': $gettext('Factors not loaded for non-active profiles.'),
          'factors': {}
        }
        return;
      }
      // get the caller's factors include the callers status to save a lookup.
      let getFactorsQueryParams = { params: {userStatus: customer.status} };
      this.$ajax.get(`/api/v1/users/${userid}/factors`, getFactorsQueryParams).then(response => {
        if (!response.data) {
          customer.mfa = {
            'status': 'UNKNOWN',
            'tooltip': $gettext('Factors failed to load'),
            'factors': {}
          }
          return;
        }

        let factors = response.data.factors;

        // Build the factors tooltip
        this.buildFactorsTooltip(customer, factors);
      }).catch(
        error => {
          // let status_code = error.response.status;
          if (error.response && error.response.data) {
            const data = error.response.data;
            if (data.message) {
              this.errorMessage = data.message;

              this.showNotification({
                title: $gettext('Factor(s) Not Loaded'),
                body: $gettext(this.errorMessage),
                type: 'error'
              });
            }
          }

          // Stop loader and show error
          customer.mfa = {
            'status': 'NOT LOADED',
            'tooltip': $gettext('Please try again.'),
            'factors': {}
          }
          console.error($gettext(`There was an unknown error: ${error}`));
        }
      );
    },
    loadFactors: function () {
      for (let [userid, customer] of Object.entries(this.customers)) {
        this.loadCallerFactors(userid, customer);
      }
    },
    searchUser: function () {

      // Trim external whitespace off any specified value.
      let haveValue = (!this.value) ? false : (this.value = this.value.trim()) !== '';
      let haveFirstName = (!this.firstName) ? false : (this.firstName = this.firstName.trim()) !== '';
      let haveLastName = (!this.lastName) ? false : (this.lastName = this.lastName.trim()) !== '';

      // Make sure we have at least some non empty value specified.
      if (!haveValue && !haveFirstName && !haveLastName) {
        return;
      }

      // Clear the current list of users
      this.customers = null;
      this.lookupLoading = true;

      // Retrieve a list of users based upon the provided criteria
      this.$ajax.get('/api/v1/users', {
        params: {
          attr: this.selectedAttr,
          value: this.value,
          firstName: this.firstName,
          lastName: this.lastName
        }
      }).then(response => {
        // success response, ensure we got back some valid data.
        if (!response.data || !response.data.status) {
          const message = (response.data && response.data.message) ? response.data.message : 'Unknown reason.';

          // We got back a positive response, but no data, display a not found error, with any details we might have.
          this.showNotification({
            title: $gettext('Caller(s) Not Found'),
            body: $gettext(message) // translate the raw API call message, this is not the best approach.
          });

          return;
        }

        // Successfully got back some data, retrieve list of users and load factors
        this.customers = response.data.users || {};
        this.optionalResultAttrs = response.data.optionalResultAttrs || [];
        this.loadFactors();

      }).catch(error => {
        // Handle error cases
        //
        // FIXME: ideally below we should leverage an error code for translations rather then the raw API message.
        // e.g. E000001 -> "No user records found for specified criteria.", that way we are less sensitive to changes
        // in the API messages going forward.
        //
        if (error.response) {
          const status_code = error.response.status;
          const data = error.response.data;

          // User(s) not found
          if (status_code === 404) {
            this.showNotification({
              title: $gettext('Caller(s) Not Found'),
              body: $gettext(data.message || 'Unknown reason.')
            });
          } else if (status_code === 401) {
            // The access token is no longer valid
            this.showNotification({
              title: $gettext('Token Expired'),
              body: $gettext(data.message || 'Invalid request token, please re-login.'),
              type: 'error',
              timeout: -1,
              showAuthBtn: true
            });
          } else {
            // log the error details for any message we don't specifically handle.
            console.error(error);

            this.showNotification({
              title: $gettext('Request Error'),
              body: $gettext(data.message || 'Unknown error.'),
              type: 'error',
              timeout: -1
            });
          }

          return;
        }

        // We got an error, but no valid response object, log what we can here.
        console.error(error);

        // display a generic error message.
        this.showNotification({
          title: $gettext('Request Error'),
          body: $gettext('An error occurred with the lookup request.'),
          type: 'error',
          timeout: -1
        });

      }).finally(() => {
        // Stop the loading indicator
        this.lookupLoading = false;
      });
    },
    showNotification(notification) {
      if (!notification) {
        return;
      }

      // Show the notification snackbar
      this.notification.title = notification.title || $gettext('Unknown');
      this.notification.body = notification.body || $gettext('Unknown notification.');
      this.notification.type = notification.type || 'info';
      // Default, hide the authentication "Login" button
      this.notification.showAuthBtn = notification.showAuthBtn || false;
      // Default hide in 5 seconds
      this.notification.timeout = notification.timeout || 5000;
      this.notification.show = true;
    }
  }
}
</script>


<style>/**
 * Temporary fix for breaking change in Vuetify v-grid
 * (see : https://github.com/vuetifyjs/vuetify/issues/11408)
 * TODO - remove this after migration
 */
.row:not([class*="my-"]):not([class*="ma-"]):not([class*="mt-"]):not([class*="mb-"]) {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}</style>
