import {translate} from 'vue-gettext';
const {gettext: $gettext} = translate;


export default {

  // gets the translated description for a factor.  this helper function
  // avoids us trying to translate user-defined strings.
  getTranslatedFactorDesc(factor) {
    if(factor && factor.type) {
      // custom factor type, no translation just use what we have.
      if(factor.type === 'custom_app' || factor.type === 'device')  {
        return factor.description
      }
      // built-in factor type, should have a translation for it.
      else {
        return $gettext(factor.description)
      }
    }
    else {
      return ""
    }
  },
  // gets the specified attribute from the factor profile.
  getFactorProfileAttribute(factor, attrName) {
    let profile_attr = null
    if(factor && factor.profile) {
      if(attrName in factor.profile) {
        profile_attr = factor.profile[attrName]
      }
    }
    return profile_attr;
  }

}
