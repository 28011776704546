import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// Translation provided by Vuetify for components (javascript)
import en from 'vuetify/lib/locale/en'
import fr from 'vuetify/lib/locale/fr'


Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, fr },
    current: navigator.language.substring(0,2),
  },
})