import { OktaAuth } from '@okta/okta-auth-js'
import axios from 'axios';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import GetTextPlugin from 'vue-gettext';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

import cvutils from '@/cvutils/cvutils';
import vuetify from '@/plugins/vuetify';

import translations from '../public/translations.json'
import App from './App.vue'
import OktaVue from './components/OktaVue'
import router from './router'



export class CallerVerify {
  // CallerVerify Object Constructor
  constructor(options) {
    this.axios = axios;
    this.axios.defaults.timeout = options.timeout || 10000;

    // prefer the baked in API URL if present and no instance level override was used.
    if(!options.baseURL && process.env.VUE_APP_ROOT_API && process.env.VUE_APP_ROOT_API !== "") {
      this.axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
    }
    // else fallback to localhost:5000 if there is override and we are running on localhost.
    else if(!options.baseURL && window.location.hostname === 'localhost') {
      this.axios.defaults.baseURL = 'http://localhost:5000';
    }
    // finally, no overrides - no built-in env vars.. default to <window.location.hostname>.
    else {
      this.axios.defaults.baseURL = options.baseURL || ('https://' + window.location.hostname);
    }
    this.renderEL = options.renderEL || '#app';
    this.widget = options.widget || false;
    this.disableLookup = options.disableLookup || false;

    // optional callback function on successful verification.
    this.verifyCBFun = options.verifyCBFun;

    // auto-lookup of caller base on input data.
    this.lookupField = options.lookupField;
    this.lookupCaller = options.lookupCaller;

    this.oktaAuth = undefined;
  }

  // public method to start the initialization of the CV Vue app.
  async initialize() {

    // attempt to use the baseURL to get our base config data.
    await this.loadBaseConfig();

    // pass along if we should render in widget mode or not.
    this.config.widget = this.widget;

    Vue.config.devtools = process.env.NODE_ENV === 'development';
    Vue.config.productionTip = false;
    Vue.prototype.$ajax = this.axios;

    this.config.verifyCBFun = this.verifyCBFun;
    this.config.lookupField = this.lookupField;
    this.config.lookupCaller = this.lookupCaller;
    this.config.disableLookup = this.disableLookup;

    // Store our config globally, there is probably a better solution for this.
    Vue.prototype.$appConfig = this.config;


    this.oktaAuth = new OktaAuth({
      clientId: this.config.client_id,
      redirectUri: window.location.origin + '/login/callback',
      issuer: this.config.issuer,
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      pkce: true
    });

    Vue.use(OktaVue, { oktaAuth: this.oktaAuth });

    const CVUtilsPlugin = {
      install() {
        Vue.cvutils = cvutils
        Vue.prototype.$cvutils = cvutils
      }
    }
    Vue.use(CVUtilsPlugin)

    Vue.use(VueCookies);
    Vue.use(VueTelInputVuetify, {vuetify})
    Vue.use(GetTextPlugin, {
      availableLanguages: {
        en: 'English',
        fr: 'Français',
      },
      defaultLanguage: navigator.language.substring(0,2),
      translations: translations}
    );

    this.app = new Vue({
      router,
      vuetify,
      render: h => h(App)
    });

    this.app.$mount(this.renderEL);
  }

  async destroy() {
    // if we have an oktaAuth object make sure we stop the background services.
    if(this.oktaAuth) {
      this.oktaAuth.stop();
    }

    // clean up app specific services and threads.
    if(this.app) {
      this.app.$destroy();
    }
  }


  loadDefaultConfig() {
    // just provide some basic defaults so we can produce a proper error page.
    this.config = {};
    this.config.client_id = "#";
    this.config.issuer = "http://localhost";
    this.config.appVersion = 'Unknown'; // FIXME: Embed this front-end version at compile time
    this.config.customer = 'TechJutsu Inc.';
    this.config.customerLogoHeight = '32';
    this.config.customerLogoWidth = '32';
    this.config.customerLogoUri = '/static/img/techjutsu-t-logo.png'; // FIXME: Not a viable widget default.
    this.config.customerLogoDarkUri = '/static/img/techjutsu-t-logo.png'; // fIXME: Not a viable widget default.

    this.config.appTheme = {};

    // default theme colors for dark theme.
    this.config.appTheme.dark = {
      "accent":"FF4081",
      "error":"FF5252",
      "info":"2196F3",
      "primary":"2196F3",
      "secondary":"424242",
      "success":"4CAF50",
      "warning":"FB8C00"
    };

    // default colors for light theme
    this.config.appTheme.light = {
      "accent":"82B1FF",
      "error":"FF5252",
      "info":"2196F3",
      "primary":"1976D2",
      "secondary":"424242",
      "success":"4CAF50",
      "warning":"FFC107"
    }

    // default colors for login gradient
    this.config.appTheme.loginGradient = {
      "end":"590000",
      "start":"A60000"
    }
  }
  async loadBaseConfig() {
    // get the application base configuration we require.
    try {
      let response = await this.axios.get('/api/v1/config/base');

      // Got a success result, confirm it has what we expected
      if (response.status === 200 && response.data && response.data.config) {
        // config successfully loaded from the server, save it.
        this.config = response.data.config;
      }
      // no config data, or unexpected response code unable to properly initialize CV.
      else {
        this.loadDefaultConfig();
        this.config.initializeError = true;
        console.error("Failed to parse initialization data.");
      }
    }
    catch(error) {
      this.loadDefaultConfig();
      this.config.initializeError = true;
      console.error("Error reading initialization data: " + error);
    }
  }
}