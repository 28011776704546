<template>
  <v-row justify="center">
  <v-dialog
    v-model="dialogNetworkIssue"
    persistent
    max-width="625"
  >
    <v-card>
      <v-card-title><translate>Network Issue Detected</translate></v-card-title>
      <v-card-text>
        <translate>There appears to be an issue with your network connection.</translate>
        <translate>The application is no longer connected.</translate>
      </v-card-text>

      <v-card-text>
        <span v-translate='{numSeconds: reconnectCountdown}'>Re-connection attempt in %{ numSeconds } seconds.</span>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="reconnectInProgress"
          :loading="reconnectInProgress"
          @click="reconnectInProgress = true; checkNetwork()"
        >
          <translate>Try Re-connect Now</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-row>

</template>

<script>
import {translate} from 'vue-gettext';
const {gettext: $gettext} = translate;

export default {
  name: 'NetworkStatus',


  data: () => ({
    // controls if the network issue dialog is displayed or not. 
    dialogNetworkIssue: false,

    // variables to control when a re-connect attempt is underway.
    reconnectInProgress: false,
    reconnectEndTime: undefined,
    reconnectInterval: null,
    checkNetworkTimeout: undefined,

    // keep track of the number of failed pings to determine if we
    // should display the network connection dialog.
    networkIssueCounter: 0,

    // reconnect countdown timer variables.
    reconnectTimeout: 30,
    reconnectCountdown: 30,
  }),
  mounted() {
    // Start ping/pong API call (detect network connectivity issues)
    this.checkNetwork();
  },
  destroyed() {
    if(this.checkNetworkTimeout) {
      clearTimeout(this.checkNetworkTimeout)
    }
  },
  methods: {
    checkNetwork: function() {
      // Clear the countdown timer
      if (this.reconnectInterval !== null) {
        clearInterval(this.reconnectInterval);
      }

      // Start API ping/pong for detecting network connectivity issues
      this.$ajax.get('/api/v1/ping').then(resp => {
        if (resp && resp.data && resp.data.status) {
          // Successful PING request, reset the network outage counter
          this.networkIssueCounter = 0;

          // show notification that we successfully re-connected to the network.
          if (this.reconnectInProgress) {
            this.$emit('showNotification', {
              title: $gettext('Network Connection'),
              body: $gettext('The application has re-established connectivity.'),
              timeout: 5000,
              type: 'info'
            });
          }

          // Clear the reconnect flag and close the network issue dialog
          this.reconnectInProgress = false;
          this.dialogNetworkIssue = false;

          // Schedule next connection check, so long as we are not about to be terminated. 
          if(!this._isDestroyed && !this._isBeingDestroyed) {          
            this.checkNetworkTimeout = setTimeout(this.checkNetwork, 5000);
          }
        } else {
          // Request data missing or status failed
          console.log('Network issue request returned negative status.');

          // Increase the network outage counter
          this.networkIssueCounter += 1;

          // Check for maximum failed network PING
          if (this.networkIssueCounter >= 5) {
            // Show the network issue dialog
            this.dialogNetworkIssue = true;

            // Clear the reconnect in progress flag and reset network issue counter
            this.reconnectInProgress = false;
            this.networkIssueCounter = 0;

            // Start re-connect countdown timer
            this.reconnectEndTime = new Date(Date.parse(new Date()) + this.reconnectTimeout * 1000);
            this.reconnectInterval = setInterval(
              this.updateReconnectCountdown,
              1000
            );
          } else {
            // Re-try the PING after 1 second
            this.checkNetworkTimeout = setTimeout(this.checkNetwork, 1000);
          }
        }
      }).catch(error => {
        console.log(`An error occurred while checking network: ${error}`);

        // Increase the network outage counter
        this.networkIssueCounter += 1;

        // Check for maximum failed network PING
        if (this.networkIssueCounter >= 5) {
          // Show the network issue dialog
          this.dialogNetworkIssue = true;

          // Clear the reconnect in progress flag and reset network issue counter
          this.reconnectInProgress = false;
          this.networkIssueCounter = 0;

          // Start re-connect countdown timer
          this.reconnectEndTime = new Date(Date.parse(new Date()) + this.reconnectTimeout * 1000);
          this.reconnectInterval = setInterval(
            this.updateReconnectCountdown,
            1000
          );
        } else {
          // Re-try the PING after 1 second
          this.checkNetworkTimeout = setTimeout(this.checkNetwork, 1000);
        }
      });
    },
    getTimeRemaining(endTime) {
      const total = Date.parse(endTime) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return {
        total,
        seconds,
        minutes,
        hours,
        days
      }
    },
    updateReconnectCountdown() {
      const timeRemaining = this.getTimeRemaining(this.reconnectEndTime);
      this.reconnectCountdown = timeRemaining.seconds;

      if (timeRemaining.total <= 0) {
        // Clear the countdown and check the network
        clearInterval(this.reconnectInterval);
        this.reconnectInProgress = true;
        this.checkNetwork();
      }
    }
  }
}
</script>
