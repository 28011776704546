<template>
  <svg class="custom-icon" width="24" height="17" viewBox="0 0 7.4083334 5.2916667">
    <g transform="translate(-111.5888,-87.161083)">
      <path style="stroke-width:0.26458332"
        d="M115.70458,87.624104 c -0.48451,0 -1.05834,0.473832 -1.05834,1.058334 0,0.584501 0.47383,1.058333 1.05834,1.058333 0.5845,0 1.05833,-0.473832 1.05833,-1.058333 0,-0.584502 -0.47383,-1.058334 -1.05833,-1.058334 z m -2.32079,0.288872 c -0.14613,0 -0.26459,0.118457 -0.26459,0.264583 0,0.146124 0.11846,0.264583 0.26459,0.264583 h 0.9064 l 0.21911,-0.529166 z m -0.42323,1.037662 c -0.14613,0 -0.26459,0.118457 -0.26459,0.264584 0,0.146124 0.11847,0.263311 0.26459,0.264583 l 0.9002,-0.0015 0.21859,-0.527617 z m 5.41672,0.922425 -1.35754,1.367875 -0.54777,-0.550354 -0.37052,0.373104 0.91829,0.926041 1.72754,-1.743563 z m -5.8446,0.115238 c -0.14613,0 -0.26459,0.118456 -0.26459,0.264583 0,0.146125 0.11846,0.264584 0.26459,0.264584 h 0.89762 l 0.2191,-0.529167 z m 3.1719,0.281637 c -1.16946,0 -2.11667,0.473606 -2.11667,1.058333 v 0.529167 h 2.64583 l -0.79375,-0.79375 0.76481,-0.764812 z m -3.60133,0.756026 c -0.14613,0 -0.26459,0.118457 -0.26459,0.264583 0,0.146124 0.11846,0.264584 0.26459,0.264584 h 0.8971 l 0.21911,-0.529167 z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'QuickVerifyIcon',
  data: () => ({})
};
</script>

<style scoped>
.custom-icon {
  fill: currentColor;
}
</style>