<template>
  <v-container fluid fill-height>
    <v-row class="mx-sm-2 mt-xs-1">
      <v-col align="left">
        <v-img
          :src="$vuetify.theme.dark ? $appConfig.customerLogoDarkUri : $appConfig.customerLogoUri"
          :max-width="$appConfig.customerLogoWidth + 'px'"
          :max-height="$appConfig.customerLogoHeight + 'px'"
        ></v-img>
        <div v-translate class="text-h3 py-3">
          Caller Verify - Error
        </div>

        <div class="py-4">
          <translate>An application error has occurred.</translate><br/>
        </div>

        <v-alert
          outlined
          type="error"
        >
          {{ error.title }} - {{ error.description }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {translate} from 'vue-gettext';
const {gettext: $gettext} = translate;

export default {
  name: 'ErrorPage',

  data: () => ({
    error: {
      title: $gettext('Unknown Error'),
      description: $gettext('An unknown error has occurred.')
    }
  }),

  methods: {
    parseErrorParameters: function() {
      let params = new URLSearchParams(window.location.search);

      let title = params.get('error');
      let description = params.get('error_description');

      if (title !== null) {
        this.error.title = title;
      }
      if (description !== null) {
        this.error.description = description;
      }
    }
  },
  mounted() {
    // Load the error and description from the URL query
    this.parseErrorParameters();
  }
}
</script>

<style scoped>
</style>
